import { TStaffRole } from "../types";

const STAFF_ROLES: TStaffRole[] = [
  "administrator",
  "limited",
  "security",
  "user-admin",
];
export const STAFF_ROLE_OPTIONS = STAFF_ROLES.map((item) => ({
  label: item.split("-").join(" "),
  value: item,
}));
