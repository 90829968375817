import axios from "axios";
import { useMutation } from "react-query";
import download from "js-file-download";

const token = localStorage.getItem("cpaat_auth") as unknown as string;
const FILE_NAME = "staff.csv";

interface IProps {
  duration?: {
    fromDate: string;

    toDate: string;
  };
  role?: string;
}

const getData = async ({
  estateId,
  props,
}: {
  estateId: string;
  props: IProps;
}) => {
  const url = `${process.env.REACT_APP_BASE_URL}/staff/estate/export/csv`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      estateId,
      "Content-Type": "text/csv",
    },
    params: {
      ...props.duration,
      role: props.role,
    },
  };

  const res = await axios.get(url, config);

  return download(res.data, FILE_NAME);
};

export const useExportStaff = () => {
  return useMutation(getData);
};
