import React, { useState } from "react";
import {
  Row,
  Col,
  Select,
  Typography,
  Button,
  Input,
  Form,
  Radio,
  Spin,
  Upload,
  message,
  DatePicker,
  Switch,
  Drawer,
} from "antd";
import type { RcFile } from "antd/es/upload/interface";

import { LoadingOutlined } from "@ant-design/icons";
import { useQuery, useQueryClient } from "react-query";
import { getEstates } from "../../../helpers/estate";
import { openNotification } from "../../../utils/notifcations";
import { emailValidationRules } from "../../../utils/validation";
import { FormPhoneInput } from "../../../components/FormPhoneInput";
import { useFetchBuildings } from "../../buildings/hooks/useFetchBuildings";
import { TBuildingUnit } from "../../buildings/types";
import { useCreatePrimaryResident } from "../hooks/useCreatePrimaryResident";
import {
  BUILDING_OWNERSHIP_OPTIONS,
  GENDER_OPTIONS,
  PREFIX_OPTIONS,
} from "../../../constants/common";

interface IProps {
  handleClose: () => void;
  open: boolean;
}

const AddResident = ({ handleClose, open }: IProps) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const [estateId, setEstateId] = useState<string>();
  const { data: estates, isSuccess: isESuccess } = useQuery(
    "estates",
    () => getEstates({ limit: "100", offset: "0" }),
    {
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        // show notification
        openNotification({
          state: "error",
          title: "Error Occured",
          description:
            err?.response.data.message ?? err?.response.data.error.message,
        });
      },
      select: (res) => {
        const result = res.data.data;
        // openNotification({
        //   state: "success",

        //   title: "Success",
        //   description: "Estate Owners Fetched !",
        //   // duration: 0.4,
        // });
        const ans: { id: string; name: string }[] = result.result.map(
          (item: any) => ({
            id: item["_id"],
            name: item.name,
          })
        );
        return ans;
      },
    }
  );
  const [fileList, setFilelist] = useState<any>([]);
  const { mutate, isLoading } = useCreatePrimaryResident();

  const handleFinish = (data: any) => {
    console.log("result", data);
    // return;
    openNotification({
      state: "info",
      title: "Wait a minute ...",
      description: <Spin />,
    });
    if (estateId) {
      mutate(
        {
          ...data,
          photo: fileList[0],
          prefix: data.prefix,
          estateId,
          phoneNumber: `${data.phone.code}-${data.phone.number}`,
          yearOfEntry: data.yearOfEntry.format("YYYY"),
          nextRenewalDate: data.nextRenewalDate.format("YYYY-MM-DD"),
        },
        {
          onError: (err: any) => {
            openNotification({
              state: "error",
              title: "Error Occured",
              description:
                err?.response.data.message ?? err?.response.data.error.message,
            });
          },
          onSuccess: (res: any) => {
            openNotification({
              state: "success",

              title: "Success",
              description: "The primary resident was created successfully",
              // duration: 0.4,
            });
            form.resetFields();
            handleClose();
            queryClient.invalidateQueries({ queryKey: ["staff"] });
          },
        }
      );
    }
  };

  const handleUpload = (val: any) => {
    setFilelist(val.fileList);
  };
  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return false;
  };
  const [units, setUnits] = useState<TBuildingUnit[]>([]);
  const { data: buildData } = useFetchBuildings(estateId, {
    isOccupied: false,
  });
  const buildings = buildData?.data;
  const handleBuildSelect = (val: string) => {
    const choosenBuildingUnits =
      buildings?.length !== 0 &&
      buildings
        ?.find((item) => item._id === val)
        ?.units?.filter((item) => item.isOccupied === false);

    if (!!choosenBuildingUnits) setUnits(() => choosenBuildingUnits);
    // building with no units
    if (!choosenBuildingUnits) setUnits(() => []);
  };

  return (
    <Drawer open={open} onClose={handleClose} title="Create Resident">
      <div className="">
        {isESuccess ? (
          <Form
            form={form}
            labelCol={{ span: 24 }}
            onFinish={handleFinish}
            requiredMark={false}
          >
            <Row gutter={[30, 5]}>
              <Col span={24}>
                <Form.Item
                  label="Select estate"
                  name={"estateId"}
                  rules={[
                    {
                      required: true,
                      message: "Field required",
                    },
                  ]}
                >
                  <Select
                    options={estates.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                    onSelect={(val: string) => setEstateId(val)}
                  />
                </Form.Item>
              </Col>
              <Col span={24} className="">
                <Typography.Text>
                  {fileList.length !== 1
                    ? "Profile Photo:"
                    : "Profile image has been inserted"}
                </Typography.Text>
                <br />

                <Upload
                  listType="picture"
                  fileList={fileList}
                  className="avatar-uploader"
                  onChange={handleUpload}
                  onRemove={(file) => {
                    const index = fileList.indexOf(file);
                    const newFileList = fileList.slice();
                    newFileList.splice(index, 1);
                    setFilelist(newFileList);
                  }}
                  beforeUpload={beforeUpload} // return false so that antd doesn't upload the picture right away
                >
                  {fileList.length !== 1 && (
                    <Button type="dashed">Upload profile image</Button>
                  )}
                </Upload>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Prefix"
                  name={"prefix"}
                  rules={[
                    {
                      required: true,
                      message: "Enter prefix",
                    },
                  ]}
                >
                  <Select options={PREFIX_OPTIONS} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="First Name"
                  name={"firstName"}
                  rules={[
                    {
                      required: true,

                      message: "Enter first name",
                    },
                  ]}
                >
                  <Input placeholder="first name"></Input>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Last Name"
                  name={"lastName"}
                  rules={[
                    {
                      required: true,

                      message: "Enter last name",
                    },
                  ]}
                >
                  <Input placeholder="last name"></Input>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Middle Name"
                  name={"middleName"}
                  rules={[
                    {
                      required: true,

                      message: "Enter middle name",
                    },
                  ]}
                >
                  <Input placeholder="middle name"></Input>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Email"
                  name={"email"}
                  rules={emailValidationRules}
                >
                  <Input placeholder="test@email.com"></Input>
                </Form.Item>
              </Col>
              <Col span={24}>
                <FormPhoneInput Form={Form} />
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Gender"
                  name={"gender"}
                  rules={[
                    {
                      required: true,

                      message: "Enter gender",
                    },
                  ]}
                >
                  <Radio.Group
                    options={GENDER_OPTIONS}
                    value="male"
                    optionType="button"
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Building"
                  name={"building"}
                  rules={[
                    {
                      required: true,

                      message: "Enter building",
                    },
                  ]}
                >
                  <Select onChange={handleBuildSelect}>
                    {buildings?.map(
                      ({ number: buildingNo, name, streetAddress, _id }) => (
                        <Select.Option key={_id} value={_id}>
                          {buildingNo}, {name}, {streetAddress}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Col>
              {units.length > 0 && (
                <Col span={24}>
                  <Form.Item
                    label="Unit"
                    name={"unit"}
                    rules={[
                      {
                        required: true,

                        message: "Enter unit",
                      },
                    ]}
                  >
                    <Select>
                      {units.map(({ number, floor }) => (
                        <Select.Option key={number} value={number}>
                          <span>{`${number} (${floor})`}</span>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              <Col span={24}>
                <Form.Item
                  label="Ownership Type"
                  name={"ownershipType"}
                  rules={[
                    {
                      required: true,

                      message: "Enter ownershipType",
                    },
                  ]}
                >
                  <Select options={BUILDING_OWNERSHIP_OPTIONS} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Year of Entry"
                  name={"yearOfEntry"}
                  rules={[
                    {
                      required: true,

                      message: "Enter yearOfEntry",
                    },
                  ]}
                >
                  <DatePicker picker="year" className="w-100" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Next Renewal Date"
                  name={"nextRenewalDate"}
                  rules={[
                    {
                      required: true,

                      message: "Enter nextRenewalDate",
                    },
                  ]}
                >
                  <DatePicker className="w-100" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <div className="space-between">
                  <Typography.Text>
                    Is Primary resident residing here?
                  </Typography.Text>
                  <Form.Item name={"isResident"}>
                    <Switch checkedChildren={"Yes"} unCheckedChildren={"No"} />
                  </Form.Item>
                </div>
              </Col>

              <Col span={24}>
                <div className="mt-20">
                  <Button
                    type="primary"
                    className="w-100"
                    size="large"
                    htmlType="submit"
                    disabled={isLoading}
                  >
                    {" "}
                    Done
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        ) : (
          <div className="h-72 flex items-center justify-center">
            <LoadingOutlined />
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default AddResident;
