import { Button, Form, Spin, DatePicker, Modal, Input, Switch } from "antd";

import { openNotification } from "../../../utils/notifcations";
import { useExportBuildings } from "../hooks/useExportBuildings";
import {
  textInputValidationRules,
  textInputValidationRulesOp,
} from "../../../utils/validation";

interface IProps {
  open: boolean;
  handleClose: Function;
  estateId: string;
}

const ExportBuildings = ({ handleClose, estateId, open }: IProps) => {
  const { mutate, isLoading } = useExportBuildings();
  const [form] = Form.useForm();
  const handleSubmit = (data: any) => {
    openNotification({
      state: "info",
      title: "Wait a minute ...",
      description: <Spin />,
    });
    mutate(
      {
        estateId,
        props: {
          duration: {
            fromDate: data.duration[0].format("YYYY-MM-DD"),
            toDate: data.duration[1].format("YYYY-MM-DD"),
          },
          search: data.search,
          isOccupied: !!data.isOccupied,
        },
      },
      {
        onError: (err: any) => {
          openNotification({
            state: "error",
            title: "Error Occured",
            description:
              err?.response.data.message ?? err?.response.data.error.message,
          });
        },
        onSuccess: (res) => {
          openNotification({
            state: "success",

            title: "Success",
            description: "The buildings data was retrieved successfully",
            // duration: 0.4,
          });
          form.resetFields();
          handleClose();
        },
      }
    );
  };

  return (
    <Modal
      title={`Export Buildings`}
      open={open}
      onCancel={() => handleClose()}
      style={{ top: 10 }}
      footer={null}
    >
      <Form
        labelCol={{ span: 24 }}
        onFinish={handleSubmit}
        requiredMark={false}
        form={form}
      >
        <Form.Item
          name={"duration"}
          label={"Duration"}
          rules={[{ required: true }]}
        >
          <DatePicker.RangePicker
            placeholder={["From", "To"]}
            className="w-full"
          />
        </Form.Item>
        <Form.Item
          name={"search"}
          label={"What search term do you want to filter by ?"}
          rules={textInputValidationRulesOp}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={"isOccupied"}
          label={"Do you want the buildings to be occupied?"}
          rules={[{ required: false }]}
        >
          <Switch unCheckedChildren={`No`} checkedChildren={`Yes`} />
        </Form.Item>

        <Form.Item className="flex justify-end">
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Export
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ExportBuildings;
