import { SelectEstate } from "../../estates/components/SelectEstate";
import { BuildingsTable } from "./BuildingsTable";
import { useAppSelector, useAppDispatch } from "../../../config/redux/hooks";
import { selectEstate } from "../../estates/slices/estate";
import { Button } from "antd";
import { useState } from "react";
import ExportBuildings from "./ExportBuildings";

export const BuildingsContainer = () => {
  const selectedEstateId = useAppSelector(
    (state) => state.estate.selectedEstateID
  );
  const dispatch = useAppDispatch();
  const [showD, setShowD] = useState<"export-data">();

  return (
    <>
      {selectedEstateId && (
        <ExportBuildings
          estateId={selectedEstateId}
          handleClose={() => setShowD(undefined)}
          open={showD === "export-data"}
        />
      )}
      <div>
        {/* content */}
        <div className="mt-4 flex flex-col gap-4">
          <div className="flex justify-between">
            <div className="flex gap-2">
              <SelectEstate
                value={selectedEstateId}
                onSelect={(id) => {
                  dispatch(selectEstate(id));
                }}
                onClear={() => {
                  dispatch(selectEstate(undefined));
                }}
              />
              {selectedEstateId && (
                <Button type="text" onClick={() => setShowD("export-data")}>
                  Export
                </Button>
              )}
            </div>
          </div>
          {selectedEstateId ? (
            <BuildingsTable estateId={selectedEstateId} />
          ) : (
            <div>Please Select an estate</div>
          )}
        </div>
      </div>
    </>
  );
};
