import React, { useState } from "react";
import {
  Row,
  Col,
  Select,
  Typography,
  Button,
  Input,
  Form,
  Radio,
  Spin,
  Upload,
  message,
  Drawer,
} from "antd";
import type { RcFile } from "antd/es/upload/interface";

import { LoadingOutlined } from "@ant-design/icons";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getEstates } from "../../../helpers/estate";
import { createStaff, ICreateStaffProps } from "../../../helpers/staff";
import { openNotification } from "../../../utils/notifcations";

interface IProps {
  handleClose: () => void;
  open: boolean;
}

const AddStaff = ({ handleClose, open }: IProps) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  let ROLES = ["limited", "user-admin", "administrator", "security"];
  const [selectedRole, setSelectedRole] = useState("");
  const handleRoleSelect = (val: any) => {
    setSelectedRole(val);
  };
  const { data: estates, isSuccess: isESuccess } = useQuery(
    "estates",
    () => getEstates({ limit: "100", offset: "0" }),
    {
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        // show notification
        openNotification({
          state: "error",
          title: "Error Occured",
          description:
            err?.response.data.message ?? err?.response.data.error.message,
        });
      },
      select: (res) => {
        const result = res.data.data;
        // openNotification({
        //   state: "success",

        //   title: "Success",
        //   description: "Estate Owners Fetched !",
        //   // duration: 0.4,
        // });
        const ans: { id: string; name: string }[] = result.result.map(
          (item: any) => ({
            id: item["_id"],
            name: item.name,
          })
        );
        return ans;
      },
    }
  );
  const [fileList, setFilelist] = useState<any>([]);
  const { mutate } = useMutation(createStaff);

  const handleFinish = (data: any) => {
    const props: ICreateStaffProps = {
      estateId: data.estateId,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      gender: data.gender,
      phoneNumber: data.phoneNumber,
      homeAddress: data.homeAddress,
      suffix: data.suffix,
      prefix: data.prefix,
      accessLevel: data.accessLevel,
      role: data.role,
      middleName: data.middleName,
      photo: fileList[0],
    };

    console.log("result", data);
    // return;
    openNotification({
      state: "info",
      title: "Wait a minute ...",
      description: <Spin />,
    });
    mutate(props, {
      onError: (err: any) => {
        openNotification({
          state: "error",
          title: "Error Occured",
          description:
            err?.response.data.message ?? err?.response.data.error.message,
        });
      },
      onSuccess: (res: any) => {
        const result = res.data.data;

        openNotification({
          state: "success",

          title: "Success",
          description: "The staff was created successfully",
          // duration: 0.4,
        });
        form.resetFields();
        handleClose();
        queryClient.invalidateQueries({ queryKey: ["staff"] });
      },
    });
  };

  const handleUpload = (val: any) => {
    setFilelist(val.fileList);
  };
  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return false;
  };

  return (
    <Drawer open={open} onClose={handleClose} title="Create Resident">
      <div className="">
        {isESuccess ? (
          <Form
            form={form}
            labelCol={{ span: 24 }}
            onFinish={handleFinish}
            requiredMark={false}
            initialValues={{
              gender: "male",
              role: ROLES[2],
              estateId: estates[0].id,
            }}
          >
            <Row gutter={[30, 5]}>
              <Col span={24} className="">
                <Typography.Text>
                  {fileList.length !== 1
                    ? "Profile Photo:"
                    : "Profile image has been inserted"}
                </Typography.Text>
                <br />

                <Upload
                  listType="picture"
                  fileList={fileList}
                  className="avatar-uploader"
                  onChange={handleUpload}
                  onRemove={(file) => {
                    const index = fileList.indexOf(file);
                    const newFileList = fileList.slice();
                    newFileList.splice(index, 1);
                    setFilelist(newFileList);
                  }}
                  beforeUpload={beforeUpload} // return false so that antd doesn't upload the picture right away
                >
                  {fileList.length !== 1 && (
                    <Button type="dashed">Upload profile image</Button>
                  )}
                </Upload>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="First name"
                  name={"firstName"}
                  rules={[
                    {
                      required: true,
                      message: "Enter first name",
                    },
                  ]}
                >
                  <Input placeholder="first name"></Input>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Last name"
                  name={"lastName"}
                  rules={[
                    {
                      required: true,
                      message: "Enter last name",
                    },
                  ]}
                >
                  <Input placeholder="last name"></Input>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Middle name"
                  name={"middleName"}
                  rules={[
                    {
                      // required: true,
                      message: "Enter middle name",
                    },
                  ]}
                >
                  <Input placeholder="middle name"></Input>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Select estate"
                  name={"estateId"}
                  rules={[
                    {
                      required: true,
                      message: "Field required",
                    },
                  ]}
                >
                  <Select
                    options={estates.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Email"
                  name={"email"}
                  rules={[
                    {
                      required: true,
                      message: "Enter email",
                    },
                  ]}
                >
                  <Input placeholder="email@eam.com"></Input>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Phone Number"
                  name={"phoneNumber"}
                  rules={[
                    {
                      required: true,
                      message: "Enter phone",
                    },
                  ]}
                >
                  <Input placeholder="e.g 09021097821"></Input>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Gender"
                  name={"gender"}
                  rules={[
                    {
                      required: true,
                      message: "Enter gender",
                    },
                  ]}
                >
                  <Radio.Group
                    options={[
                      { label: "Male", value: "male" },
                      { label: "Female", value: "female" },
                    ]}
                    optionType="button"
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Role"
                  name={"role"}
                  rules={[
                    {
                      required: true,
                      message: "Enter role",
                    },
                  ]}
                >
                  <Select onSelect={handleRoleSelect}>
                    {ROLES.map((role) => (
                      <Select.Option key={role} value={role}>
                        {`${role}`}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {selectedRole === "security" && (
                <Col span={24}>
                  <Form.Item
                    label="Access level"
                    name={"accessLevel"}
                    rules={[
                      {
                        required: true,
                        message: "Enter access level",
                      },
                    ]}
                  >
                    <Select>
                      {["limited", "full-access"].map((role) => (
                        <Select.Option key={role} value={role}>
                          {`${role}`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              {/* not needed   */}
              {/* <Col span={24}>
            <Form.Item
              label="Suffix"
              name={"suffix"}
              rules={[
                {
                  required: true,
                  message: "Enter suffix",
                },
              ]}
            >
              <Select>
                {["jnr", "snr"].map((role) => (
                  <Select.Option key={role} value={role}>
                    {`${role}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col> */}
              <Col span={24}>
                <Form.Item
                  label="House Address"
                  name={"homeAddress"}
                  rules={[
                    {
                      required: true,
                      message: "Enter address",
                    },
                  ]}
                >
                  <Input.TextArea placeholder="House Number and Street"></Input.TextArea>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Button
                  type="primary"
                  className="w-100"
                  size="large"
                  htmlType="submit"
                >
                  {" "}
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        ) : (
          <div className="h-72 flex items-center justify-center">
            <LoadingOutlined />
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default AddStaff;
