import { Drawer, Button } from "antd";
import React, { useState } from "react";
import { SelectEstate } from "../../estates/components/SelectEstate";
import AddResident from "./AddResident";
import { ResidentsTable } from "./ResidentsTable";
import { useAppSelector, useAppDispatch } from "../../../config/redux/hooks";
import { selectEstate } from "../../estates/slices/estate";
import { PermissionRestrictor } from "../../../components/permission-restrictor/PermissionRestrictor";
import ExportPrimaryResidents from "./ExportPrimaryResidents";

export const ResidentsContainer = () => {
  const [showD, setShowD] = useState<"add-resident" | "export-data">();
  const selectedEstateId = useAppSelector(
    (state) => state.estate.selectedEstateID
  );
  const dispatch = useAppDispatch();
  return (
    <>
      {selectedEstateId && (
        <ExportPrimaryResidents
          estateId={selectedEstateId}
          handleClose={() => setShowD(undefined)}
          open={showD === "export-data"}
        />
      )}
      <div>
        <AddResident
          handleClose={() => setShowD(undefined)}
          open={showD === "add-resident"}
        />

        {/* content */}
        <div className="mt-8 flex flex-col gap-4">
          <div className="flex justify-between">
            <div className="flex gap-2">
              <SelectEstate
                value={selectedEstateId}
                onSelect={(id) => {
                  dispatch(selectEstate(id));
                }}
                onClear={() => {
                  dispatch(selectEstate(undefined));
                }}
              />
              {selectedEstateId && (
                <PermissionRestrictor requiredPermissions={["create-resident"]}>
                  <Button type="text" onClick={() => setShowD("export-data")}>
                    Export
                  </Button>
                </PermissionRestrictor>
              )}
            </div>
            <PermissionRestrictor requiredPermissions={["create-resident"]}>
              <Button type="primary" onClick={() => setShowD("add-resident")}>
                Add Resident
              </Button>
            </PermissionRestrictor>
          </div>
          {selectedEstateId ? (
            <ResidentsTable estateId={selectedEstateId} />
          ) : (
            <div>Please Select an estate</div>
          )}
        </div>
      </div>
    </>
  );
};
