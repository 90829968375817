import { Typography, Button, Select } from "antd";
import React, { useState } from "react";

import StaffTable from "../components/StaffTable";
import { SelectEstate } from "../../estates/components/SelectEstate";
import { TStaffRole } from "../types";
import { useAppSelector, useAppDispatch } from "../../../config/redux/hooks";
import { selectEstate } from "../../estates/slices/estate";
import { PermissionRestrictor } from "../../../components/permission-restrictor/PermissionRestrictor";
import { STAFF_ROLE_OPTIONS } from "../constants";
import ExportStaff from "../components/ExportStaff";
import AddStaff from "../components/AddStaff";

const Staff = () => {
  const selectedEstateId = useAppSelector(
    (state) => state.estate.selectedEstateID
  );
  const dispatch = useAppDispatch();
  const [role, setRole] = useState<TStaffRole>();
  const [showD, setShowD] = useState<"export-data" | "add-staff">();

  return (
    <>
      {selectedEstateId && (
        <ExportStaff
          estateId={selectedEstateId}
          handleClose={() => setShowD(undefined)}
          open={showD === "export-data"}
        />
      )}
      <AddStaff
        handleClose={() => setShowD(undefined)}
        open={showD === "add-staff"}
      />
      <div>
        <Typography.Title level={3}>Staff</Typography.Title>
        {/* content */}
        <div className="mt-8 flex flex-col gap-4">
          <div className="flex justify-between">
            <div className="flex gap-2">
              <SelectEstate
                value={selectedEstateId}
                onSelect={(id) => {
                  dispatch(selectEstate(id));
                }}
                onClear={() => {
                  dispatch(selectEstate(undefined));
                }}
              />
              {selectedEstateId && (
                <Button type="text" onClick={() => setShowD("export-data")}>
                  Export
                </Button>
              )}
            </div>

            <Select
              options={STAFF_ROLE_OPTIONS}
              value={role}
              onClear={() => setRole(undefined)}
              onSelect={(val: TStaffRole) => setRole(val)}
              allowClear
              placeholder="Filter by role"
            />
            <PermissionRestrictor requiredPermissions={["create-staff"]}>
              <Button type="primary" onClick={() => setShowD("add-staff")}>
                Add Staff
              </Button>
            </PermissionRestrictor>
          </div>
          {selectedEstateId ? (
            <StaffTable estateId={selectedEstateId} role={role} />
          ) : (
            <div>Please Select an estate</div>
          )}
        </div>
      </div>
    </>
  );
};

export default Staff;
